<template>
    <div>
      <Pane />
  
      <a-card class="container">
        <a-row>
          <a-col :span="24">
            <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">  
              <a-form-model-item>
                <a-select
                  show-search
                  v-model="form.orgId"
                  placeholder="部门名称"
                  style="width: 150px"
                >
                  <a-select-option
                    v-for="item in organizationList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.uniqueName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-select v-model="form.role" placeholder="成员角色" style="width: 150px">
                  <a-select-option
                    v-for="item in specialStaff"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
  
              <a-form-model-item>
                <a-space>
                  <a-button @click="query" type="primary">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
  
        <Padding />
        <div style="background-color: #ececec; padding: 20px;">
            <div v-for="item,index in list" :key="index" style="margin-bottom: 20px;width: 32%;display: inline-block;vertical-align: top;margin-right: 1%;">
                <a-card :bordered="false" :hoverable="true" @click.prevent="$router.push($route.path + '/list?id=' + item.orgId)" style="border-radius: 5px;">
                    <div slot="cover" style="display: flex;align-items: center;padding: 10px 0 10px 33.3%;border-bottom: 1px solid #ddd;">
                        <a-icon type="home" style="font-size: 25px;margin-right: 5px;"/>
                        <span style="font-size: 18px;">{{ item.orgName }}</span>
                    </div>
                    <div style="margin-top: 10px;min-height: 80px; max-height: 200px;overflow-y: auto;">
                        <div v-for="item,index in item.roleList" :key="index" style="display: flex;align-items: center;margin-bottom: 5px;padding-left: 33.3%;">
                            <div style="display: flex;align-items: center;">
                                <a-icon type="user" style="font-size: 25px;margin-right: 5px;"/>
                                <DataDictFinder
                                dictType="qualitySafety.specialStaff"
                                :dictValue="item"
                                />
                            </div>
                        </div>
                    </div>
                </a-card>
            </div>
        </div>
      </a-card>
    </div>
  </template>
  
  <script>
  import request from "@/api/request";
  import { mapGetters } from "vuex";
  function queryCompanyOrg() {
    return request({
      url: "/user-service/org/queryCompanyOrg",
    });
  }

  function fetchList(data) {
    return request({
      url: "/office-service/org/special/staff/queryMapList",
      method: "post",
      data
    });
  }
  
  function remove(id) {
    return request({
      url: "/office-service/quality/fee/budget/invalid/" + id,
    });
  }
  
  export default {
    data() {
      return {
        form: {},
  
        loading: false,
        list: [],
        total: 0,
        current: 1,
        pageSize: 10,
        organizationList: [],
        visible: false,
        detail: {}
      };
    },
  
    computed: {
      ...mapGetters("setting", ["findDataDict"]),
      specialStaff() {
        return this.findDataDict("qualitySafety.specialStaff");
      },
    },
  
    mounted() {
      queryCompanyOrg().then(res => {
        this.organizationList = res;
      })
      this.getList();
    },
  
    methods: {
      getList() {
        this.loading = true;
        fetchList({
          ...this.form
        })
          .then(res => {
            if (Array.isArray(res)) {
              this.list = res;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
  
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.current = 1;
        this.form = {};
        this.getList();
      },
      deleteText(text) {
        const that = this;
        this.$confirm({
          title: "确认要作废吗？",
          onOk() {
            remove(text.id).then(() => {
              that.getList();
            });
          }
        });
      }
    }
  };
  </script>
  